<template>
  <div class="app-customizer">
    <v-btn
      icon
      class="app-customizer-toggler rounded-0"
      :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
      color="white"
      large
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <v-icon size="25">
        {{ icons.mdiDotsHorizontal }}
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="isCustomizerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="300"
      class="app-customizer-drawer"
    >
      <!-- Heading -->
      <div class="app-customizer-header customizer-section py-3">
        <h6
          class="font-weight-semibold text-xl text--primary"
        >
          Help menu
        </h6>
        <span class="text--secondary">Select an action</span>
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isCustomizerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <div class="customizer-section">
          <div class="w-full pa-2">
            <v-btn
              color="success"
              dark
              class="w-full"
              @click.prevent=""
            >
              Save
            </v-btn>
          </div>
          <div class="w-full pa-2">
            <v-btn
              color="primary"
              dark
              class="w-full"
              @click.prevent=""
            >
              Duplicate
            </v-btn>
          </div>
          <div class="w-full pa-2">
            <v-btn
              color="error"
              dark
              class="w-full"
              @click.prevent=""
            >
              Delete
            </v-btn>
          </div>
          <div class="w-full pa-2">
            <v-btn
              color="error"
              dark
              outlined
              class="w-full"
              @click.prevent=""
            >
              Close
            </v-btn>
          </div>
        </div>
        <v-divider></v-divider>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { mdiClose, mdiCog, mdiDotsHorizontal } from '@mdi/js'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    PerfectScrollbar,
  },
  setup() {
    const isCustomizerOpen = ref(false)

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isCustomizerOpen,

      // Perfect scrollbar options
      perfectScrollbarOptions,

      // Icons
      icons: {
        mdiCog,
        mdiClose,
        mdiDotsHorizontal,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: auto !important;
}

.app-customizer-toggler {
  position: fixed !important;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 30%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
