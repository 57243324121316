import {
  mdiAccountOutline,
  mdiCalendarOutline,
  mdiCreditCardOutline,
  mdiEmailOutline,
  mdiFileDocumentOutline,
  mdiFileOutline,
  mdiHomeOutline,
  mdiMessageTextOutline, mdiOfficeBuildingOutline,
  mdiReceipt,
} from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard-analytics',
  },
  {
    title: 'Tenders',
    icon: mdiFileOutline,
    to: 'admin-tender-list',
    children: [
      {
        title: 'Tender List',
        to: 'admin-tender-list',
      },
      {
        title: 'Create Tender',
        to: 'admin-tender-create',
      },
    ],
  },
  {
    title: 'Companies',
    icon: mdiOfficeBuildingOutline,
    to: 'admin-tender-list',
    children: [
      {
        title: 'Company List',
        to: 'admin-company-list',
      },
      {
        title: 'Add Company',
        to: 'admin-company-create',
      },
    ],
  },
  {
    title: 'Reports',
    icon: mdiReceipt,
    to: 'admin-report-list',
  },
  {
    title: 'Subscriptions',
    icon: mdiCreditCardOutline,
    to: 'admin-subscription-list',
  },
  {
    title: 'User Management',
    icon: mdiAccountOutline,
    children: [
      {
        title: 'User List',
        to: 'admin-user-list',
      },
      {
        title: 'Add User',
        to: 'admin-user-create',
      },
    ],
  },
  {
    subheader: 'References',
  },
  {
    title: 'App examples',
    children: [
      {
        title: 'Calendar',
        icon: mdiCalendarOutline,
        to: 'apps-calendar',
      },
      {
        title: 'Chat',
        icon: mdiMessageTextOutline,
        to: 'apps-chat',
      },
      {
        title: 'Email',
        icon: mdiEmailOutline,
        to: 'apps-email',
      },
      {
        title: 'Invoice',
        icon: mdiFileDocumentOutline,
        children: [
          {
            title: 'List',
            to: 'apps-invoice-list',
          },
          {
            title: 'Preview',
            to: { name: 'apps-invoice-preview', params: { id: 4987 } },
          },
          {
            title: 'Edit',
            to: { name: 'apps-invoice-edit', params: { id: 4987 } },
          },
          {
            title: 'Add',
            to: { name: 'apps-invoice-add' },
          },
        ],
      },

      {
        title: 'Pages',
        icon: mdiFileOutline,
        children: [
          {
            title: 'Authentication',
            children: [
              {
                title: 'Login V1',
                to: 'auth-login-v1',
                target: '_blank',
              },
              {
                title: 'Login V2',
                to: 'auth-login-v2',
                target: '_blank',
              },
              {
                title: 'Register V1',
                to: 'auth-register-v1',
                target: '_blank',
              },
              {
                title: 'Register V2',
                to: 'auth-register-v2',
                target: '_blank',
              },
              {
                title: 'Forgot Password V1',
                to: 'auth-forgot-password-v1',
                target: '_blank',
              },
              {
                title: 'Forgot Password V2',
                to: 'auth-forgot-password-v2',
                target: '_blank',
              },
              {
                title: 'Reset Password V1',
                to: 'auth-reset-password-v1',
                target: '_blank',
              },
              {
                title: 'Reset Password V2',
                to: 'auth-reset-password-v2',
                target: '_blank',
              },
            ],
          },
          {
            title: 'Miscellaneous',
            children: [
              {
                title: 'Under Maintenance',
                to: 'misc-under-maintenance',
                target: '_blank',
              },
              {
                title: 'Error',
                to: 'misc-error',
                target: '_blank',
              },
              {
                title: 'Coming Soon',
                to: 'misc-coming-soon',
                target: '_blank',
              },
              {
                title: 'Not Authorized',
                to: 'misc-not-authorized',
                target: '_blank',
              },
            ],
          },
          {
            title: 'Knowledge Base',
            to: 'page-knowledge-base',
          },
          {
            title: 'Account Settings',
            to: 'page-account-settings',
          },
          {
            title: 'Pricing',
            to: 'page-pricing',
          },
          {
            title: 'FAQ',
            to: 'page-faq',
          },
        ],
      },
    ],
  },

]
