import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    companyTypes: [
      { key: 'client', name: 'Client' },
      { key: 'supplier', name: 'Supplier' },
      { key: 'quality_assurance', name: 'Quality assurance' },
    ],
    userTypes: [
      { key: 'admin', name: 'Admin' },
      { key: 'user', name: 'User' },
    ],
    baseStatuses: [
      { key: 'pending', name: 'Pending' },
      { key: 'active', name: 'Active' },
      { key: 'disabled', name: 'Disabled' },
    ],
    languages: [
      { key: 'de', name: 'German' },
      { key: 'fr', name: 'French' },
      { key: 'it', name: 'Italian' },
      { key: 'en', name: 'English' },
    ],
    tenderStatuses: [
      { key: 'draft', name: 'Draft' },
      { key: 'active', name: 'Active' },
      { key: 'semiinactive', name: 'Semiinactive' },
      { key: 'inactive', name: 'Inactive' },
      { key: 'archived', name: 'Archived' },
      { key: 'trashed', name: 'Trashed' },
    ],
    tenderCategories: [
      { key: 'not_selected', name: 'Not specified' },
      { key: 'delivery', name: 'Delivery' },
      { key: 'service', name: 'Service' },
      { key: 'construction_work', name: 'Construction work' },
      { key: 'competition', name: 'Competition' },
    ],
    platformCategories: [
      { key: 'not_selected', name: 'Not specified' },
      { key: 'miscellaneous', name: 'Miscellaneous' },
    ],
    procedureType: [
      { key: 'not_selected', name: 'Not specified' },
      { key: 'open_procedure', name: 'Open procedure' },
      { key: 'selective_procedure', name: 'Selective procedure' },
      { key: 'hands_free_procedure', name: 'Hands-free procedure' },
      { key: 'invitation_procedure', name: 'Invitation procedure' },
    ],
    tenderTypes: [
      { key: 'international', name: 'International tender' },
      { key: 'local', name: 'Local tender' },
      { key: 'internal', name: 'Internal tender' },
    ],
    tenderActions: [
      { key: 'preview', name: 'Preview' },
      { key: 'duplicate', name: 'Duplicate' },
      { key: 'edit', name: 'Edit' },
      { key: 'delete', name: 'Delete' },
    ],
    addressOptions: [
      { key: 'objectLocation', name: 'Object location address' },
      { key: 'tenderOwnerAddress', name: 'Tender owner address' },
      { key: 'additionalContactPerson', name: 'Additional contact person data' },
    ],
    addressTypeOptions: [
      { key: 'contact_address', name: 'Contact address' },
      { key: 'delivery_address', name: 'Delivery address' },
      { key: 'object_address', name: 'Object address' },
      { key: 'billing_address', name: 'Billing address' },
      { key: 'other_address', name: 'Other address' },
    ],
  },
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },

  getters: {
    statusesWithTrashed: state => {
      const temp = [...state.baseStatuses]
      temp.push({ key: 'trashed', name: 'Trashed' })

      return temp
    },

    formatKeyForTranslation: state => key => state.addressTypeOptions.filter(item => item.key === key)[0].name,
  },
})
