export default {

  // menus and theme translations
  Dashboards: 'Dashboards',
  Analytics: 'Analytics',
  eCommerce: 'eCommerce',
  Dashboard: 'Dashboard',
  Tenders: 'Tenders',
  'Create Tender': 'Create Tender',
  'Tender List': 'Tender List',
  CRM: 'CRM',
  Companies: 'Companies',
  'Company List': 'Company List',
  'Add Company': 'Add Company',
  'Add User': 'Add User',
  Reports: 'Reports',
  Subscriptions: 'Subscriptions',
  'User Management': 'User Management',
  References: 'References',
  'App examples': 'App examples',
  'User interface': 'User interface',
  'Forms & tables': 'Forms & tables',
  'Charts & maps': 'Charts & maps',
  'Other examples': 'Other examples',
  Calendar: 'Calendar',
  Chat: 'Chat',
  Email: 'Email',
  Invoice: 'Invoice',
  List: 'List',
  Preview: 'Preview',
  Edit: 'Edit',
  Add: 'Add',
  User: 'User',
  'User List': 'User List',
  'User View': 'User View',
  'APPS AND PAGES': 'APPS AND PAGES',
  Pages: 'Pages',
  Authentication: 'Authentication',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscellaneous',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Knowledge Base': 'Knowledge Base',
  'Account Settings': 'Account Settings',
  Pricing: 'Pricing',
  FAQ: 'FAQ',
  'USER INTERFACE': 'USER INTERFACE',
  Typography: 'Typography',
  Icons: 'Icons',
  Gamification: 'Gamification',
  Cards: 'Cards',
  Basic: 'Basic',
  Statistics: 'Statistics',
  Advance: 'Advance',
  Actions: 'Actions',
  Chart: 'Chart',
  Components: 'Components',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Button',
  Menu: 'Menu',
  'Expansion Panel': 'Expansion Panel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Timeline',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'FORMS & TABLES',
  'Forms Elements': 'Forms Elements',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'File Input',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Others',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',

  // user types select
  'Super admin': 'Super admin',
  'Cleangreen admin': 'Cleangreen admin',
  Admin: 'Admin',

  // company types select
  Client: 'Client',
  Supplier: 'Supplier',
  'Quality assurance': 'Quality assurance',
  'Technical support': 'Technical support',
  'Platform owner': 'Platform owner',

  // statuses select
  Pending: 'Pending',
  Active: 'Active',
  Disabled: 'Disabled',
  Trashed: 'Trashed',

  // languages select
  English: 'English',
  German: 'German',
  French: 'French',
  Italian: 'Italian',

  // addresses
  'Contact address': 'Contact address',
  'Delivery address': 'Delivery address',
  'Object address': 'Object address',
  'Billing address': 'Billing address',
  'Other address': 'Other address',

  // tender categories select
  'Not specified': 'Not specified',
  Delivery: 'Delivery',
  Service: 'Service',
  'Construction work': 'Construction work',
  Competition: 'Competition',

  // tender types select
  'International tender': 'International tender',
  'Local tender': 'Local tender',
  'Internal tender': 'Internal tender',

  // tender actions select
  Duplicate: 'Duplicate',
  Delete: 'Delete',

  // input field options select
  Subtitle: 'Subtitle',
  Description: 'Description',
  'Short text': 'Short text',
  'Long text': 'Long text',
  Number: 'Number',
  'Radio button': 'Radio button',
  'File upload': 'File upload',
  Date: 'Date',

  // tender statuses select
  Draft: 'Draft',
  Semiinactive: 'Semiinactive',
  Inactive: 'Inactive',
  Archived: 'Archived',

  // procedure type select
  'Open procedure': 'Open procedure',
  'Selective procedure': 'Selective procedure',
  'Hands-free procedure': 'Hands-free procedure',
  'Invitation procedure': 'Invitation procedure',

  yes: 'Yes',
  no: 'No',

  tenders: {
    tenders: 'Tenders',
    tenderList: 'Tender List',
    tenderCreate: 'Create Tender',
  },
  general: {
    typeToSearch: 'Type to search',
    statusFilter: 'Status filter',
    categoryFilter: 'Category filter',
    language: 'Language',
    or: 'or',
    welcomeMessage: 'Welcome to Esora!',
    forgotPassword: 'Forgot Password',
    save: 'Save',
    close: 'Close',
    ok: 'ok',
    cancel: 'Cancel',
    anErrorOccurred: 'An error occurred, please try again.',
    status: 'Status',
    name: 'Name',
    edit: 'Edit',
    delete: 'Delete',
    restore: 'Restore',
    details: 'Details',
    user: 'User',
    company: 'Company',
    type: 'Type',
  },
  login: {
    signInMessage: 'Please sign-in to your account',
    email: 'Email',
    password: 'Password',
    rememberMe: 'Remember me',
    login: 'Login',
    newOnPlatform: 'New on our platform?',
    createAccount: 'Create an account',
    invalidCredentials: 'Invalid data, please correct the fields and try again.',
    emailVerified: 'Your email is successfully verified',
  },
  register: {
    fillUpMessage: 'Please fill up the following form',
    userAgreement: 'I agree to',
    privacyAndTerms: 'privacy policy & terms',
    alreadyHaveAccount: 'Already have an account?',
    signInInstead: 'Sign in instead',
    successfullyRegistered: 'Your account is successfully created. Our administration will check your account and '
      + 'enable it if all data is in order or ask for additional information if needed. Please verify your email in meantime.',
    invalidRegistrationData: 'Entered data is invalid. If you used this email before, please login.',
    resend: 'Resend',
    resendEmailMessage: 'Did not get confirmation email?',
    companyType: 'Choose a profile type',
  },
  forgotPassword: {
    enterEmail: 'Enter your email and we\'ll send you instructions to reset your password',
    sendLink: 'Send reset link',
    backToLogin: 'Back to login',
    emailSent: 'We sent you an email with reset password instructions',
    resetPassword: 'Reset password',
    passwordSuccessfullyReset: 'Your password is changed. You can log in now with your new password',
  },
  tender: {
    create: 'Create a new tender',
    edit: 'Update tender',
    tenderActions: 'Tender actions',
    mainData: 'Main data',
    date: 'Date',
    platformReferenceNumber: 'Platform reference number',
    clientReferenceNumber: 'Client reference number',
    tenderCategory: 'Tender category',
    procedureType: 'Procedure type',
    company: 'Company',
    tenderOwner: 'Tender owner',
    addressData: 'Address data',
    mainCompanyAddress: 'Main company address',
    address: 'Address',
    zipCode: 'Zip code',
    city: 'City',
    country: 'Country',
    tenderObjectLocation: 'Tender object location',
    tenderOwnerData: 'Tender owner data',
    additionalContactPersonData: 'Additional contact person data',
    firstName: 'First name',
    lastName: 'Last name',
    phone: 'Phone',
    mobile: 'Mobile',
    email: 'Email',
    position: 'Position',
    title: 'Tender title',
    mainTitle: 'Enter the main title',
    internalTitle: 'Enter the internal title (not public)',
    tenderDescription: 'Tender description',
    descriptionField: 'Enter the main tender description',
    dataContainer: 'Data container ',
    containerSubtitle: 'Enter the subtitle',
    containerDescription: 'Enter the section description',
    addRow: 'Add Row',
    remove: 'Remove',
    removeSubtitle: 'Remove subtitle',
    removeDescription: 'Remove description',
    removeField: 'Remove field',
    editField: 'Edit field',
    addField: 'Add field',
    removeRow: 'Remove this row',
    addContainer: '+ Add container',
    removeContainerNotice: 'Are you sure? The data might be lost',
    inputFieldType: 'What input fields would you like to add?',
    fieldLabel: 'Field label',
    chooseInputType: 'Choose input field type',
    addressNotice: 'What address type would you like to add?',
    chooseAddressType: 'Choose address type',
    chooseInputFieldTitle: 'What input fields would you like to add?',
    moveDown: 'Move down',
    moveUp: 'Move up',
    preview: 'Tender preview',
    removeAddress: 'Remove address',
    makeOffer: 'Make offer',
    acceptingOffersFrom: 'Accepting offers from',
    acceptingOffersTo: 'Accepting offers to',
    dateFormatHint: 'Format YYYY-MM-DD',
    platformCategory: 'Esora category',
    exitPreview: 'Exit preview',
    resetAndExit: 'Reset and exit',
    removeStep: 'Remove step',
    dataRequested: 'This data is requested from the other party',
    enterStepperTitle: 'Enter stepper title',
    stepperTitle: 'Stepper title',
    details: 'Tender details',
    dataSaved: 'Tender data successfully saved',
    addressDataNotSelected: 'You have to choose address type and one of offered addresses in order to proceed',
    tenderTypeStateAreaContract: 'Is this state area contract?',
    step1: 'Step 1',
    actionMenu: {
      addContainer: 'Add new container',
      duplicateTender: 'Duplicate tender',
      deleteTender: 'Delete tender',
    },
  },
  companies: {
    companyList: 'Company list',
    edit: 'Update company data',
    create: 'Add new company',
    name: 'Name',
    address: 'Address',
    addressAdditional: 'Address line 2',
    zipCode: 'Zip code',
    city: 'City',
    state: 'State',
    country: 'Country',
    phone: 'Phone',
    mobile: 'Mobile',
    email: 'Email',
    website: 'Website URL',
    correspondenceEmail: 'Correspondence email',
    companyIdentificationNumber: 'Identification number',
    companyIdentificationType: 'Identification type',
    dataSaved: 'Company data saved',
    companyAddresses: 'Company addresses',
    companyUsers: 'Company users',
    addOrEditCompanyAddress: 'Add/Edit company address',
    addressType: 'Address type',
    streetNumber: 'Street number',
    additionalAddressLine1: 'Additional address line 1',
    additionalAddressLine2: 'Additional address line 2',
    language: 'Language',
    description: 'Description',
  },
  users: {
    userList: 'User list',
    create: 'Add new user',
    addOrEditUser: 'Add/Edit user',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    correspondenceEmail: 'Correspondence email',
    language: 'Language',
    correspondenceLanguage: 'Correspondence language',
    mobile: 'Mobile',
    phone: 'Phone',
    placementAddress: 'Placement address',
    title: 'Title',
    function: 'Function',
    twoFactorVerificationMethod: 'Two factor authentication method',
    userRole: 'User role',
    userType: 'User type',
    password: 'Password',
    passwordConfirmation: 'Password confirmation',
    dataSaved: 'User data successfully saved',
    validationError: 'There was an error saving the user. Please check the fields and try again.',
  },
  languages: {
    german: 'German',
    french: 'French',
    italian: 'Italian',
    english: 'English',
  },
  footer: {
    copyright: 'Copyright',
    impressum: 'Impressum',
  },

  // backend error keys
  invalid_credentials: 'Invalid credentials.',
  company_account_not_enabled: 'Company account is not enabled, please contact support',
  user_account_not_enabled: 'Your account is not enabled, please contact support',
  email_taken: 'Sorry, this email address is already registered. Please use another one',
  password_not_matched: 'Password and password confirmation do not match, please correct the fields',
  not_found: 'Requested resource was not found on the server',
  weak_password: 'Field password must contain at least one uppercase, lowercase, special character and digit with min 8 chars',
  general_error: 'An error occurred. Please try again or contact support',
  general_success: 'Action successfully completed',
}
